import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { Heading, Icon } from "@maistro/components";
import classNames from "classnames";
import { first } from "lodash";
import React, { useMemo, useState } from "react";
import { createUseStyles } from "react-jss";

import FileList from "components/FileList/FileList";
import MaistroFormikPassIcon from "components/FormikFields/MaistroFormikPassIcon";
import useProjectFiles from "features/project/hooks/useProjectFiles";
import ModerateScoringForm from "features/project/tenderCriteria/scoring/ModerateScoringForm";
import ScoringForm from "features/project/tenderCriteria/scoring/ScoringForm";
import useCurrentUser from "hooks/useCurrentUser";
import stringService from "services/stringService";
import { ITheme } from "styles/themes/types";
import { AnswerDto } from "types/dtos/questions/AnswerResponseDto";
import { ScoreResponseDto } from "types/dtos/questions/ScoreResponseDto";
import EntityType from "types/enums/questions/EntityType";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        marginBottom: theme.spacing.medium,
    },
    supplierDropdown: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing.xSmall,
        background: theme.colors.medLightGray,
        borderRadius: theme.spacing.xSmall,
        padding: theme.spacing.small,
        marginBottom: theme.spacing.small,
        cursor: "pointer",

        "&:hover": {
            filter: "brightness(0.8)",
        },
    },
    checkmark: {
        margin: 0,
    },
    chevron: {
        fontSize: 14,
        marginRight: theme.spacing.xSmall,
    },
    supplierName: {
        flexGrow: 1,
    },
    contents: {
        display: "flex",
        flexDirection: "column",
    },
    answer: {
        ...theme.typography.base,
        padding: `${theme.spacing.medium}px ${theme.spacing.large}px`,
        marginBottom: theme.spacing.small,
        background: theme.colors.white,
        border: `1px solid ${theme.colors.medLightGray}`,
        borderRadius: theme.spacing.xSmall,
        overflowY: "auto",
        wordBreak: "break-word",
    },
    hidden: {
        display: "none",
    },

    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
        answer: {
            height: 350,
        },
        contents: {
            flexDirection: "row",
            gap: theme.spacing.xLarge,

            "& > *:first-child": {
                flexBasis: "65%",
            },

            "& > *:last-child": {
                flexBasis: "35%",
            },
        },
    },
}));

interface IAnswerAccordionProps {
    projectUuid: string;
    questionUuid: string;
    answer: AnswerDto;
    setScore: (score: ScoreResponseDto) => void;
    expandedByDefault: boolean;
    canScore: boolean;
    canModerateScore: boolean;
    showScoreBreakdown: (answer: AnswerDto) => void;
}

const AnswerAccordion: React.FC<IAnswerAccordionProps> = (props) => {
    const classes = useStyles();

    const {
        projectUuid,
        questionUuid,
        answer,
        setScore,
        expandedByDefault,
        canScore,
        canModerateScore,
        showScoreBreakdown,
    } = props;
    const [isExpanded, setIsExpanded] = useState(expandedByDefault);

    const { projectFiles: answerFiles } = useProjectFiles(projectUuid, EntityType.Answer, answer.answerUuid);

    const { myUuid } = useCurrentUser();

    const score = first(
        answer.scores?.filter((x) => x.scoredByUserUuid === myUuid && x.isOverride === canModerateScore),
    );

    const averageScore = useMemo(() => {
        const scoresOnly = answer.scores?.filter((x) => x.isOverride === false).map((x) => x.score);
        if (!scoresOnly || scoresOnly.length === 0) {
            return 0;
        }
        return Math.round(scoresOnly.reduce((a, b) => a + b, 0) / scoresOnly.length);
    }, [answer.scores]);

    const testid = stringService.toKebabCase(answer.respondingCompanyName);

    return (
        <div className={classes.container}>
            <div className={classes.supplierDropdown} onClick={() => setIsExpanded(!isExpanded)} data-testid={testid}>
                <MaistroFormikPassIcon
                    className={classes.checkmark}
                    isVisible={score?.score !== undefined && score?.score !== null && !canModerateScore}
                />
                <Heading className={classes.supplierName} variant="h4">
                    {answer.respondingCompanyName}
                </Heading>
                <Icon className={classes.chevron} icon={isExpanded ? faChevronUp : faChevronDown} />
            </div>

            <div
                className={classNames(
                    { [classes.contents]: canScore || canModerateScore },
                    { [classes.hidden]: !isExpanded },
                )}
            >
                <div>
                    <div className={classes.answer} data-testid={`${testid}-answer`}>
                        {answer.answerText}
                    </div>
                    {answer.hasAttachments && <FileList files={answerFiles} canDownload testid={`${testid}-files`} />}
                </div>
                {canScore && !canModerateScore && (
                    <ScoringForm
                        projectUuid={projectUuid}
                        questionUuid={questionUuid}
                        answerUuid={answer.answerUuid}
                        score={score}
                        setScore={setScore}
                        testid={`${testid}-form`}
                    />
                )}
                {canModerateScore && (
                    <ModerateScoringForm
                        projectUuid={projectUuid}
                        questionUuid={questionUuid}
                        answerUuid={answer.answerUuid}
                        score={score}
                        average={averageScore}
                        setScore={setScore}
                        testid={`${testid}-form`}
                        showScoreBreakdown={() => showScoreBreakdown(answer)}
                    />
                )}
            </div>
        </div>
    );
};

export default AnswerAccordion;
