import { Alert, Button, ExpansionPanel, Label, ReadOnlyField } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { Paper } from "components";
import FileList from "components/FileList/FileList";
import NotInterestedWithdrawButtons from "features/project/shared/projectResponse/NotInterestedWithdrawButtons";
import { IProjectResponseInformation } from "features/project/types";
import currencyService from "services/currencyService";
import dateTimeService from "services/dateTimeService";
import { ITheme } from "styles/themes/types";
import FileDto from "types/dtos/files/FileDto";
import BudgetType from "types/enums/projects/BudgetType";
import ProjectType from "types/enums/projects/ProjectType";
import SupplierSubmissionStatus from "types/enums/supplierSubmissions/SupplierSubmissionStatus";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.small,
    },
    row: {
        display: "grid",
        gridTemplateAreas:
            '"projectName"' +
            '"companyName"' +
            '"projectManager"' +
            '"budget"' +
            '"timescales"' +
            '"responseDate"' +
            '"questionDeadline"' +
            '"startDate"' +
            '"endDate"',
        gap: theme.spacing.medium,
    },
    column: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },
    categories: {
        margin: `${theme.spacing.xSmall / 3}px ${theme.spacing.xSmall / 3}px`,
    },
    documents: {
        padding: `${theme.spacing.xSmall}px 0`,

        "& label": {
            display: "inline-block",
            marginBottom: theme.spacing.medium,
        },
    },
    buttonControl: {
        marginTop: theme.spacing.large,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing.medium,
    },
    projectName: {
        gridArea: "projectName",
    },
    companyName: {
        gridArea: "companyName",
    },
    questionDeadline: {
        gridArea: "questionDeadline",
    },
    projectManager: {
        gridArea: "projectManager",
    },
    startDate: {
        gridArea: "startDate",
    },
    budget: {
        gridArea: "budget",
    },
    endDate: {
        gridArea: "endDate",
    },
    responseDate: {
        gridArea: "responseDate",
    },
    timescales: {
        gridArea: "timescales",
    },

    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        row: {
            gridTemplateAreas:
                '"projectName responseDate responseDate responseDate responseDate"' +
                '"companyName questionDeadline questionDeadline questionDeadline questionDeadline"' +
                '"projectManager startDate startDate startDate startDate"' +
                '"budget endDate endDate endDate endDate"' +
                '"timescales timescales timescales timescales timescales"',
        },
    },
}));

interface IProjectResponseDisplay {
    projectResponseInformation: IProjectResponseInformation;
    projectFiles: FileDto[];
    cancelProjectResponse: () => void;
    withdrawProjectResponse: () => void;
    updateProjectResponseStatus: () => void;
    deadlineHasPassed: boolean;
}

const statusesAllowingEditing = [
    SupplierSubmissionStatus.Submitted,
    SupplierSubmissionStatus.Interested,
    SupplierSubmissionStatus.Invited,
    SupplierSubmissionStatus.NotInterested,
    SupplierSubmissionStatus.Responding,
];

const ProjectResponseDisplay: React.FC<IProjectResponseDisplay> = (props) => {
    const classes = useStyles();

    const { t } = useTranslation();
    const {
        projectResponseInformation,
        projectFiles,
        cancelProjectResponse,
        withdrawProjectResponse,
        updateProjectResponseStatus,
        deadlineHasPassed,
    } = props;

    const canEdit = statusesAllowingEditing.find((s) => s === projectResponseInformation?.status) != null;

    const renderBudget = () => {
        if (projectResponseInformation.budgetType !== BudgetType.Budget) {
            return (
                <ReadOnlyField
                    testid="project-budget"
                    label={t("projectBrief.buyerBudget.label")}
                    value={t(`budgetTypes.names.${projectResponseInformation.budgetType}`)}
                    className={classes.budget}
                />
            );
        }

        if (
            projectResponseInformation.budgetType === BudgetType.Budget &&
            projectResponseInformation.showBudgetToSupplier
        ) {
            return (
                <ReadOnlyField
                    testid="project-budget"
                    label={t("projectBrief.buyerBudget.label")}
                    value={`${currencyService.toCurrencyIcon(projectResponseInformation.budgetCurrency)}
                                ${projectResponseInformation.buyerBudget.toLocaleString()}`}
                    className={classes.budget}
                />
            );
        }

        return (
            <ReadOnlyField
                testid="project-budget"
                label={t("projectBrief.buyerBudget.label")}
                value={t("budgetTypes.names.Unknown")}
                className={classes.budget}
            />
        );
    };

    return (
        <div className={classes.container}>
            {deadlineHasPassed && (
                <Alert type="error" label={t("projectResponse.deadlineExpired")} testid="deadline-expired" />
            )}
            <Paper>
                <div className={classes.row}>
                    <ReadOnlyField
                        testid="project-name"
                        label={t("projectBrief.name.label")}
                        value={projectResponseInformation.projectName}
                        className={classes.projectName}
                    />
                    <ReadOnlyField
                        testid="company-name"
                        label={t("projectBrief.company.label")}
                        value={projectResponseInformation.companyName}
                        className={classes.companyName}
                    />
                    <ReadOnlyField
                        testid="project-manager"
                        label={t("projectBrief.manager.label")}
                        value={projectResponseInformation.manager}
                        className={classes.projectManager}
                    />
                    {renderBudget()}
                    <ReadOnlyField
                        testid="project-response-date"
                        label={t("projectBrief.responseDate.label")}
                        value={dateTimeService.toShortDateTimeFormat(projectResponseInformation.tenderResponseDeadline)}
                        className={classes.responseDate}
                    />
                    {projectResponseInformation.projectType === ProjectType.Quote && (
                        <ReadOnlyField
                            testid="project-timescale"
                            label={t("projectBrief.timescale.label")}
                            value={projectResponseInformation.timescale}
                            className={classes.timescales}
                        />
                    )}
                    {projectResponseInformation.projectType === ProjectType.Tender && (
                        <>
                            <ReadOnlyField
                                testid="clarification-question-deadline"
                                label={t("projectBrief.clarificationQuestionDeadline.label")}
                                value={dateTimeService.toShortDateTimeFormat(
                                    projectResponseInformation.clarificationQuestionDeadline,
                                )}
                                className={classes.questionDeadline}
                            />
                            <ReadOnlyField
                                testid="contract-start-date"
                                label={t("projectBrief.contractStartDate.label")}
                                value={dateTimeService.toShortDateTimeFormat(
                                    projectResponseInformation.targetProjectStartDate,
                                )}
                                className={classes.startDate}
                            />
                            <ReadOnlyField
                                testid="contract-end-date"
                                label={t("projectBrief.contractEndDate.label")}
                                value={dateTimeService.toShortDateTimeFormat(
                                    projectResponseInformation.targetProjectCompleteDate,
                                )}
                                className={classes.endDate}
                            />
                        </>
                    )}
                </div>
            </Paper>
            <ExpansionPanel label={t("projectBrief.description.label")} testid="project-description-panel">
                <ReadOnlyField testid="project-description" value={projectResponseInformation.description} />
            </ExpansionPanel>
            {projectFiles && projectFiles.length > 0 && (
                <div className={classes.documents}>
                    <Label content="Documents" name="files" />
                    <FileList
                        files={projectFiles.filter(
                            (file) =>
                                file.linkedCompanyUuid === projectResponseInformation.buyerCompanyUuid ||
                                file.linkedCompanyUuid == null,
                        )}
                        canDownload
                        testid="project-documents"
                    />
                </div>
            )}
            <div className={classes.buttonControl}>
                <Button
                    testid="project-response-continue-button"
                    label={t("projectResponse.continueToQuoteResponse", {
                        projectType: projectResponseInformation.projectType,
                    })}
                    size="large"
                    chevron
                    onClick={updateProjectResponseStatus}
                    disabled={deadlineHasPassed || !canEdit}
                />
                <NotInterestedWithdrawButtons
                    supplierSubmission={projectResponseInformation}
                    declareNotInterested={cancelProjectResponse}
                    withdraw={withdrawProjectResponse}
                />
            </div>
        </div>
    );
};

export default ProjectResponseDisplay;
