import { LoadingScreen, useToaster } from "@maistro/components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { setScorerScoringComplete } from "api/questions/tenderCriteriaQuestionsApi";
import { Loader } from "components";
import TitleCtaType from "components/Layout/types/TitleCtaType";
import ProjectPreviewDrawer from "features/project/components/ProjectPreviewDrawer";
import useNavTabs from "features/project/hooks/useNavTabs";
import useProject from "features/project/hooks/useProject";
import useProjectFiles from "features/project/hooks/useProjectFiles";
import { useTenderCriteria } from "features/project/hooks/useTenderCriteria";
import ScoreTenderSummaryDisplay from "features/project/tenderCriteria/scoringSummary/ScoreTenderSummaryDisplay";
import useAppDispatch from "hooks/useAppDispatch";
import useCurrentUser from "hooks/useCurrentUser";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { resetLayout, setBack, setPageTitle, setSecondaryNavItems, setTitleCtas, setTooltip } from "store/layoutSlice";
import { setTenderScoringComplete } from "store/projectSlice";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

const ScoreTenderSummaryContainer: React.FC = () => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [isProjectPreviewOpen, setIsProjectPreviewOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const toast = useToaster();

    const { buyerTabItems } = useNavTabs();
    const { myUuid: userUuid, userHasProjectPermission } = useCurrentUser();
    const { projectInformation, isFetching: isFetchingProject } = useProject();
    const userIsProjectManager = userUuid === projectInformation.managerUuid;
    const { projectFiles, projectHasFilesOfAnyKind, isFetchingFiles } = useProjectFiles(projectInformation.uuid);
    const {
        questions,
        sections,
        completedContributorScorers,
        totalContributorScorers,
        isScoringCompleteForUser,
        isScoringCompleteForProject,
        downloadTenderResponsesReport,
        isFetching: isFetchingTender,
    } = useTenderCriteria({
        projectUuid: projectInformation.uuid,
    });
    const isScoringComplete = isScoringCompleteForUser || isScoringCompleteForProject;
    const displayScoringClosedMessage = !isScoringCompleteForUser && isScoringCompleteForProject;

    const handleDownload = useCallback(() => {
        setIsDownloading(true);
        downloadTenderResponsesReport().finally(() => setIsDownloading(false));
    }, [downloadTenderResponsesReport]);

    useEffect(() => {
        setIsLoading(isFetchingTender || isFetchingProject || isFetchingFiles);
    }, [isFetchingProject, isFetchingTender, isFetchingFiles]);

    const canAskQuestion = useMemo(() => {
        return userHasProjectPermission(PermissionsEnum.CanAskClarificationQuestion, projectInformation.uuid);
    }, [projectInformation.uuid, userHasProjectPermission]);

    useEffect(() => {
        dispatch(resetLayout());
        dispatch(setBack(null));
        if (!isLoading) {
            dispatch(
                setBack({
                    route: buildRoute(routes.projects.monitor, { projectUuid: projectInformation.uuid }),
                }),
            );
            dispatch(setPageTitle(t("tenderSummaryScreen.title")));
            dispatch(setSecondaryNavItems(buyerTabItems));
            dispatch(
                setTooltip([
                    { text: t("tenderSummaryScreen.tooltips.line1") },
                    { text: t("tenderSummaryScreen.tooltips.line2") },
                    { text: t("tenderSummaryScreen.tooltips.line3") },
                    { text: t("tenderSummaryScreen.tooltips.line4") },
                    { text: t("tenderSummaryScreen.tooltips.line5") },
                ]),
            );

            const titleCtas = [
                {
                    type: TitleCtaType.ProjectPreview,
                    onClick: () => setIsProjectPreviewOpen(true),
                },
                {
                    type: TitleCtaType.Export,
                    onClick: handleDownload,
                },
            ];

            if (canAskQuestion) {
                titleCtas.push({
                    type: TitleCtaType.AskQuestion,
                    onClick: () =>
                        navigate(
                            buildPath(routes.projects.askClarificationQuestion, {
                                projectUuid: projectInformation.uuid,
                            }),
                        ),
                });
            }

            dispatch(setTitleCtas(titleCtas));
        }

        return () => {
            dispatch(resetLayout());
            dispatch(setBack(null));
        };
    }, [
        buyerTabItems,
        canAskQuestion,
        dispatch,
        handleDownload,
        isLoading,
        projectFiles,
        projectInformation,
        projectInformation.uuid,
        navigate,
        t,
    ]);

    const completeScoring = async () => {
        if (!projectInformation.uuid) return;

        const response = await setScorerScoringComplete(projectInformation.uuid, userUuid);

        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("tenderCriteria.score.api.submitScoresError"));
            return;
        }

        dispatch(
            setTenderScoringComplete({
                isProjectManager: userIsProjectManager,
            }),
        );
        if (userIsProjectManager) {
            navigate(buildPath(routes.projects.awardSupplier, { projectUuid: projectInformation.uuid }));
        } else {
            navigate(buildPath(routes.projects.monitor, { projectUuid: projectInformation.uuid }));
        }
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            {isDownloading && (
                <LoadingScreen
                    title={t("tenderCriteria.score.export.title")}
                    message={t("tenderCriteria.score.export.message")}
                    duration={35000}
                    testid="loading-screen"
                />
            )}
            <ScoreTenderSummaryDisplay
                projectUuid={projectInformation.uuid}
                completedContributorScorers={completedContributorScorers}
                totalContributorScorers={totalContributorScorers}
                questions={questions}
                sections={sections}
                completeScoring={completeScoring}
                isProjectManager={userIsProjectManager}
                isScoringComplete={isScoringComplete}
                displayScoringClosedMessage={displayScoringClosedMessage}
                projectHasFiles={projectHasFilesOfAnyKind}
                buyerCompanyUuid={projectInformation.companyUuid}
            />
            <ProjectPreviewDrawer
                projectFiles={projectFiles}
                isOpen={isProjectPreviewOpen}
                close={() => setIsProjectPreviewOpen(false)}
            />
        </>
    );
};

export default ScoreTenderSummaryContainer;
