import { LoadingScreen, useToaster } from "@maistro/components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { setScorerScoringComplete } from "api/questions/tenderCriteriaQuestionsApi";
import TitleCtaType from "components/Layout/types/TitleCtaType";
import ProjectPreviewDrawer from "features/project/components/ProjectPreviewDrawer";
import useNavTabs from "features/project/hooks/useNavTabs";
import useProject from "features/project/hooks/useProject";
import useProjectFiles from "features/project/hooks/useProjectFiles";
import { useTenderCriteria } from "features/project/hooks/useTenderCriteria";
import ScoreTenderQuestionDisplay from "features/project/tenderCriteria/scoring/ScoreTenderQuestionDisplay";
import useAppDispatch from "hooks/useAppDispatch";
import useCurrentUser from "hooks/useCurrentUser";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { resetLayout, setBack, setPageTitle, setSecondaryNavItems, setTitleCtas, setTooltip } from "store/layoutSlice";
import { setTenderScoringComplete } from "store/projectSlice";
import { ScoreResponseDto } from "types/dtos/questions/ScoreResponseDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

interface ITenderQuestionProps {
    questionUuid: string;
}

const ScoreTenderQuestionContainer: React.FC = () => {
    const params = useParams();
    const { questionUuid } = params as unknown as ITenderQuestionProps;

    const [isSubmittingScores, setIsSubmittingScores] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isProjectPreviewOpen, setIsProjectPreviewOpen] = useState(false);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const toast = useToaster();
    const navigate = useNavigate();

    const { myUuid: userUuid, userHasProjectPermission } = useCurrentUser();
    const { projectInformation } = useProject();
    const userIsProjectManager = userUuid === projectInformation.managerUuid;
    const { projectFiles } = useProjectFiles(projectInformation.uuid);
    const {
        questions,
        setQuestions,
        sections,
        completedContributorScorers,
        totalContributorScorers,
        isScoringCompleteForUser,
        isScoringCompleteForProject,
        downloadTenderResponsesReport,
    } = useTenderCriteria({
        projectUuid: projectInformation.uuid,
    });
    const isScoringComplete = isScoringCompleteForUser || isScoringCompleteForProject;
    const { buyerTabItems } = useNavTabs();

    const canAskQuestion = useMemo(() => {
        return userHasProjectPermission(PermissionsEnum.CanAskClarificationQuestion, projectInformation.uuid);
    }, [projectInformation.uuid, userHasProjectPermission]);

    const canScoreAnswers = useMemo(
        () =>
            !isScoringComplete &&
            userHasProjectPermission(
                PermissionsEnum.CreateScore,
                projectInformation.uuid,
                projectInformation.companyUuid,
            ),
        [isScoringComplete, userHasProjectPermission, projectInformation.uuid, projectInformation.companyUuid],
    );

    const canModerateScores = useMemo(
        () =>
            isScoringCompleteForProject &&
            userHasProjectPermission(
                PermissionsEnum.OverrideScore,
                projectInformation.uuid,
                projectInformation.companyUuid,
            ),
        [
            isScoringCompleteForProject,
            userHasProjectPermission,
            projectInformation.uuid,
            projectInformation.companyUuid,
        ],
    );

    const handleDownload = useCallback(() => {
        setIsDownloading(true);
        downloadTenderResponsesReport().finally(() => setIsDownloading(false));
    }, [downloadTenderResponsesReport]);

    useEffect(() => {
        dispatch(
            setPageTitle(canModerateScores ? t("tenderCriteria.moderateScore.title") : t("tenderCriteria.score.title")),
        );
        dispatch(
            setTooltip(
                canModerateScores
                    ? t("tenderCriteria.moderateScore.tooltip")
                    : [
                          { text: t("tenderCriteria.score.tooltip.paragraph1") },
                          { text: t("tenderCriteria.score.tooltip.paragraph2") },
                          { text: t("tenderCriteria.score.tooltip.paragraph3") },
                          { text: t("tenderCriteria.score.tooltip.paragraph4") },
                          { text: t("tenderCriteria.score.tooltip.paragraph5") },
                      ],
            ),
        );
        dispatch(
            setBack({
                route: buildRoute(routes.projects.tenderSummary, { projectUuid: projectInformation.uuid }),
            }),
        );
        dispatch(setSecondaryNavItems(buyerTabItems));

        const titleCtas = [
            {
                type: TitleCtaType.ProjectPreview,
                onClick: () => setIsProjectPreviewOpen(true),
            },
        ];
        if (canAskQuestion) {
            titleCtas.push({
                type: TitleCtaType.AskQuestion,
                onClick: () =>
                    buildPath(routes.projects.askClarificationQuestion, { projectUuid: projectInformation.uuid }),
            });
        }

        if (!canModerateScores) {
            titleCtas.push({
                type: TitleCtaType.Export,
                onClick: handleDownload,
            });
        }
        dispatch(setTitleCtas(titleCtas));

        return () => {
            dispatch(resetLayout());
        };
    }, [
        buyerTabItems,
        canAskQuestion,
        canModerateScores,
        dispatch,
        handleDownload,
        projectFiles,
        projectInformation,
        navigate,
        t,
    ]);

    const setScore = useCallback(
        (score: ScoreResponseDto) => {
            setQuestions(score, questionUuid);
        },
        [questionUuid, setQuestions],
    );

    const submitScores = async () => {
        setIsSubmittingScores(true);
        const response = await setScorerScoringComplete(projectInformation.uuid, userUuid);

        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("tenderCriteria.score.api.submitScoresError"));
            setIsSubmittingScores(false);
            return;
        }

        dispatch(
            setTenderScoringComplete({
                isProjectManager: userIsProjectManager,
            }),
        );
        toast.success(t("tenderCriteria.score.api.submitScoresSuccess"));
        navigate(buildPath(routes.projects.tenderSummary, { projectUuid: projectInformation.uuid }));
    };

    return (
        <>
            {isDownloading && (
                <LoadingScreen
                    title={t("tenderCriteria.score.export.title")}
                    message={t("tenderCriteria.score.export.message")}
                    duration={35000}
                    testid="loading-screen"
                />
            )}
            <ScoreTenderQuestionDisplay
                projectUuid={projectInformation.uuid}
                questionUuid={questionUuid}
                questions={questions}
                sections={sections}
                completedContributorScorers={completedContributorScorers}
                totalContributorScorers={totalContributorScorers}
                isProjectManager={userIsProjectManager}
                setScore={setScore}
                submitScores={submitScores}
                userUuid={userUuid}
                isSubmittingScores={isSubmittingScores}
                canScoreAnswers={canScoreAnswers}
                canModerateScores={canModerateScores}
            />
            <ProjectPreviewDrawer
                projectFiles={projectFiles}
                isOpen={isProjectPreviewOpen}
                close={() => setIsProjectPreviewOpen(false)}
            />
        </>
    );
};

export default ScoreTenderQuestionContainer;
