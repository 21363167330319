import { Table, ITableColumn } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";

import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";

interface ISupplierTableProps {
    suppliers: Array<SupplierSubmissionResponseDto>;
    columns: ITableColumn[];
    canAwardSupplier: boolean;
    sortingAlgorithm?: (a: SupplierSubmissionResponseDto, b: SupplierSubmissionResponseDto) => number;
}

const SupplierTable: React.FC<ISupplierTableProps> = (props) => {
    const { suppliers, columns, canAwardSupplier, sortingAlgorithm } = props;

    const { t } = useTranslation();

    const displayData = suppliers
        .filter((supplier) => !!supplier.scoring || !canAwardSupplier)
        .map((supplier) => ({
            ...supplier,
            supplierCompanyName: supplier._embedded.supplierCompanyName,
            matchRating: supplier._included.matchRating * 5,
        }));
    if (sortingAlgorithm) {
        displayData.sort(sortingAlgorithm);
    }

    return (
        <Table columns={columns} data={displayData} noDataDisplay={t("common.noResults")} testid="suppliers-table" />
    );
};

export default SupplierTable;
