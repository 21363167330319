import { AxiosResponse } from "axios";

import env from "config/EnvironmentVariables";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { createAxiosInstance } from "api/commonApi";
import { ProjectFileResponseDto } from "types/dtos/files/ProjectFileResponseDto";
import { ProjectFilesResponseDto } from "types/dtos/files/ProjectFilesResponseDto";
import FileDto from "types/dtos/files/FileDto";
import apiErrorService, { ApiResponse } from "services/apiErrorService";
import EntityType from "types/enums/questions/EntityType";
import { IsProjectFilesArchiveAvailableResponseDto } from "types/dtos/files/IsProjectFilesArchiveAvailableResponseDto";
import { ProjectHasAnyFilesResponseDto } from "types/dtos/files/ProjectHasAnyFilesResponseDto";

const api = createAxiosInstance(env.OrchestrationApiUrl);
const baseApiUrl = "/projects";

export const linkProjectFiles = (
    projectUuid: string,
    fileUuids: string[],
    entityType = EntityType.Project,
    entityUuid?: string,
): Promise<AxiosResponse<ProjectFileResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    return api.put(`${baseApiUrl}/${projectUuid}/media-storage`, { fileUuids, entityType, entityUuid });
};

export const listProjectFiles = (
    projectUuid: string,
    entityType = EntityType.Project,
    entityUuid?: string,
): Promise<AxiosResponse<ProjectFilesResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    let url = `${baseApiUrl}/${projectUuid}/files?entityType=${entityType}`;

    if (entityUuid) {
        url += `&entityUuid=${entityUuid}`;
    }

    return api.get(url);
};

export const listAllProjectFiles = (
    projectUuid: string,
): Promise<AxiosResponse<ProjectFilesResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    const url = `${baseApiUrl}/${projectUuid}/all-files`;
    return api.get(url);
};

export const deleteProjectFile = (projectUuid: string, fileUuid: string): Promise<AxiosResponse> => {
    return api.delete(`${baseApiUrl}/${projectUuid}/files/${fileUuid}`);
};

export const downloadMedia = (fileUuid: string): Promise<AxiosResponse<Blob> | AxiosResponse<TransactionErrorDto>> => {
    return api.get(`/media-storage/${fileUuid}`, { responseType: "blob" });
};

export const uploadToTempStorage = (file: File): Promise<ApiResponse<FileDto>> => {
    const data = new FormData();
    data.append("file", file);

    return api
        .post(`/media-storage`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((response) => {
            return apiErrorService.transformResponseTypeOnError(response);
        });
};

export const projectHasAnyFiles = (
    projectUuid: string,
): Promise<AxiosResponse<ProjectHasAnyFilesResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    return api.get(`${baseApiUrl}/${projectUuid}/project-has-any-files`);
};

// Archive
export const createProjectFilesArchive = (projectUuid: string): Promise<AxiosResponse> => {
    return api.post(`${baseApiUrl}/${projectUuid}/files-archive`);
};

export const getProjectFilesArchive = (
    projectUuid: string,
): Promise<AxiosResponse<Blob> | AxiosResponse<TransactionErrorDto>> => {
    return api.get(`${baseApiUrl}/${projectUuid}/files-archive`, { responseType: "blob" });
};

export const isProjectFilesArchiveAvailable = (
    projectUuid: string,
): Promise<AxiosResponse<IsProjectFilesArchiveAvailableResponseDto> | AxiosResponse<TransactionErrorDto>> => {
    return api.get(`${baseApiUrl}/${projectUuid}/is-files-archive-available`);
};
