import { Popup, RichTextEditorField, htmlService } from "@maistro/components";
import { FormikErrors, useFormikContext } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import HelpBanner from "components/HelpBanner/HelpBanner";
import { ICategoryProps } from "components/shared";
import { IProjectInformation } from "features/project/types";
import usePlatformFeatures from "hooks/usePlatformFeatures";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import useCompany from "hooks/useCompany";
import FeatureToggle from "types/enums/companies/FeatureToggle";
import useCurrentUser from "hooks/useCurrentUser";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";
import useReduxSelector from "hooks/useReduxSelector";

interface IProjectDescriptionFieldProps {
    disableSubmission: boolean;
    buttonOnly: boolean;
    projectUuid: string;
    newProjectUuid: string | null;
    processText: (
        inputText: string,
        setFieldValue: (
            field: string,
            value: ICategoryProps[],
            shouldValidate?: boolean,
        ) => Promise<void | FormikErrors<IProjectInformation>>,
        categories: Array<ICategoryProps>,
    ) => void;
}

const ProjectDescriptionField: React.FC<IProjectDescriptionFieldProps> = (props) => {
    const { disableSubmission, buttonOnly, projectUuid, newProjectUuid, processText } = props;

    const { values, setFieldValue } = useFormikContext<IProjectInformation>();
    const [isProjectBriefPopupOpen, setIsProjectBriefPopupOpen] = useState(false);
    const [currentBrief, setCurrentBrief] = useState("");
    const { theme } = useReduxSelector((state) => state.themeState);

    const navigate = useNavigate();
    const { t } = useTranslation();

    const { isAIAssistAvailable } = usePlatformFeatures();
    const { companyHasFeature } = useCompany();
    const { userHasProjectPermission } = useCurrentUser();

    const goToBriefGeneration = () => {
        navigate(
            buildPath(routes.projects.generateProjectDescription, {
                projectUuid: props.newProjectUuid ?? props.projectUuid,
            }),
        );
    };

    const showAiAssist =
        userHasProjectPermission(PermissionsEnum.CreateProject, projectUuid) &&
        isAIAssistAvailable &&
        companyHasFeature(FeatureToggle.AIAssist, true);

    const helpBanner = () => {
        if (!showAiAssist) {
            return undefined;
        }

        return (
            <HelpBanner
                title={t("projectBrief.description.guidedQuestionBannerTitle")}
                message={t("projectBrief.description.guidedQuestionBannerMessage")}
                buttonLabel={t("projectBrief.description.guidedQuestionBannerCta")}
                onClick={() => {
                    if (htmlService.hasContent(currentBrief)) {
                        setIsProjectBriefPopupOpen(true);
                    } else {
                        goToBriefGeneration();
                    }
                }}
                disabled={disableSubmission || (!newProjectUuid && projectUuid === "new")}
                buttonOnly={buttonOnly}
            />
        );
    };

    return (
        <>
            <RichTextEditorField
                testid="project-brief-description"
                label={t("projectBrief.description.label")}
                labelComponent={helpBanner()}
                name="description"
                required
                tooltip={t(`projectBrief.description.${showAiAssist ? "guidedTooltip" : "tooltip"}`)}
                onChange={(value) => {
                    setCurrentBrief(value);
                    processText(value, setFieldValue, values.categories);
                }}
                joditLicense={theme.joditLicence}
            />
            <Popup
                title={t("popups.projectBrief.aiAssist.title")}
                message={t("popups.projectBrief.aiAssist.message")}
                isOpen={isProjectBriefPopupOpen}
                primaryActionText={t("popups.projectBrief.aiAssist.cta.primary")}
                onPrimaryAction={() => goToBriefGeneration()}
                secondaryActionText={t("popups.projectBrief.aiAssist.cta.secondary")}
                onSecondaryAction={() => setIsProjectBriefPopupOpen(false)}
                onClose={() => setIsProjectBriefPopupOpen(false)}
                variant="warning"
                testid="project-brief-generation-popup"
            />
        </>
    );
};

export default ProjectDescriptionField;
