import { Button } from "@maistro/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import { ICommonProps } from "components/shared";
import useQuestionStyles from "features/project/shared/tender-questions/useQuestionStyles";
import QuestionRow from "features/project/components/tender-questions/QuestionRow";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { ITheme } from "styles/themes/types";
import { AnswerDto } from "types/dtos/questions/AnswerResponseDto";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";

const useStyles = createUseStyles((theme: ITheme) => ({
    button: {
        marginLeft: theme.spacing.small,
        marginBottom: theme.spacing.small,
        height: theme.layout.navBarHeight,
        width: 155,
        flexGrow: 0,
        flexShrink: 0,
    },
}));

interface IScoreTenderSummaryQuestionRowProps extends ICommonProps {
    question: QuestionResponseDto;
    projectUuid: string;
    haveScores: (answer: AnswerDto) => boolean;
    canScoreAnswers: boolean;
    canModerateScores: boolean;
}

const ScoreTenderSummaryQuestionRow: React.FC<IScoreTenderSummaryQuestionRowProps> = (props) => {
    const questionClasses = useQuestionStyles();
    const classes = useStyles();
    const { question, projectUuid, haveScores, canScoreAnswers, canModerateScores, testid } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const isScored = question.answers.some(haveScores);
    const isComplete = question.answers.every(haveScores);

    const navigateToScoring = () => {
        navigate(buildPath(routes.projects.scoreTenderQuestion, { projectUuid, questionUuid: question.questionUuid }));
    };

    const renderButton = () => {
        if (canModerateScores) {
            return (
                <Button
                    className={classes.button}
                    variant="outline"
                    label={t("tenderSummaryScreen.moderateScores")}
                    onClick={navigateToScoring}
                    testid={`${testid}-moderate`}
                />
            );
        }

        return isScored ? (
            <Button
                className={questionClasses.button}
                variant="outline"
                label={t("tenderSummaryScreen.editScores")}
                onClick={navigateToScoring}
                testid={`${testid}-edit`}
            />
        ) : (
            <Button
                className={questionClasses.button}
                color="dark"
                label={t("tenderSummaryScreen.viewAnswer")}
                onClick={navigateToScoring}
                testid={`${testid}-view`}
            />
        );
    };

    return (
        <div className={questionClasses.questionContainer}>
            <QuestionRow
                className={questionClasses.questionRow}
                testid={`question-${question.questionUuid}`}
                questionText={question.questionText}
                isComplete={isComplete}
                hasAttachments={question.answers.some((x) => x.hasAttachments)}
                hideActionIcons
            />

            {(canScoreAnswers || canModerateScores) && renderButton()}
        </div>
    );
};
export default ScoreTenderSummaryQuestionRow;
