import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { t } from "i18next";

import { getProject } from "api/projectApi";
import { getProjectUsers } from "api/projectSettingsApi";
import {
    getIsScoringCompleteForProject,
    getIsScoringCompleteForScorer,
    getProjectSections,
    listQuestions,
} from "api/questions/tenderCriteriaQuestionsApi";
import { getTaxonomyItemsForProject } from "api/taxonomyApi";
import {
    sortByConditionalThenAlphabetically,
    sortByWeightingThenAlphabetically,
} from "features/project/shared/tender-questions/useQuestionSorters";
import { IProjectInformation } from "features/project/types";
import type { AppStore } from "store/store";
import { I18Options } from "store/types/I18NextTypes";
import { ProjectResponseDto } from "types/dtos/projects/ProjectResponseDto";
import { IsEntityScoringCompleteResponseDto } from "types/dtos/questions/IsEntityScoringCompleteResponseDto";
import { ListQuestionsResponseDto } from "types/dtos/questions/ListQuestionsResponseDto";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import { ScoreResponseDto } from "types/dtos/questions/ScoreResponseDto";
import { GetProjectSectionsResponseDto } from "types/dtos/questions/sections/GetProjectSectionsResponseDto";
import { SectionDto } from "types/dtos/questions/sections/SectionDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import CategorySelectionStatus from "types/enums/CategorySelectionStatus";
import CurrencyType from "types/enums/CurrencyType";
import BudgetType from "types/enums/projects/BudgetType";
import ProjectStatus from "types/enums/projects/ProjectStatus";
import RolesEnum from "types/enums/rolesPermissions/RolesEnum";

interface ErrorShape {
    translation: string;
    options?: I18Options;
}

interface ITenderCriteria {
    questions: Array<QuestionResponseDto>;
    sections: Array<SectionDto>;
    isScoringCompleteForUser: boolean;
    isScoringCompleteForProject: boolean;
    completedContributorScorers: number;
    totalContributorScorers: number;
}

export interface IProjectState {
    fetchingErrorMessage: string;
    isFetchingProject: boolean;
    projectInformation: IProjectInformation;
    isFetchingTender: boolean;
    tenderCriteria: ITenderCriteria;
}

interface IRejectedProjectValue {
    projectInformation: IProjectInformation;
    fetchingErrorMessage: string;
}

interface IFetchTenderCriteriaRequest {
    projectUuid?: string;
    userUuid: string;
}

interface IRejectTenderValue {
    tenderCriteria: ITenderCriteria;
    fetchingErrorMessage: string;
}

const defaultTenderCriteria = {
    questions: [],
    sections: [],
    isScoringCompleteForProject: false,
    isScoringCompleteForUser: false,
    completedContributorScorers: 0,
    totalContributorScorers: 0,
} as ITenderCriteria;

const defaultProjectInformation = {
    uuid: "",
    companyUuid: "",
    projectName: "",
    companyName: "",
    manager: "",
    managerUuid: "",
    description: "",
    timescale: "",
    budgetCurrency: CurrencyType.GBP,
    buyerBudget: 0,
    showBudgetToSupplier: false,
    budgetType: BudgetType.Budget,
    categories: [],
    status: ProjectStatus.Create,
    tenderResponseDeadline: null as unknown as Date,
    clarificationQuestionDeadline: null as unknown as Date,
    targetProjectStartDate: null as unknown as Date,
    targetProjectCompleteDate: null as unknown as Date,
    expressionOfInterestDeadline: null as unknown as Date,
    decisionSummary: "",
    isLoading: true,
    clientCompanyUuid: undefined,
    clientName: undefined,
    sponsorUserUuid: "",
    sponsorName: "",
    contributors: [],
} as IProjectInformation;

async function fetchScoringData(
    projectUuid: string,
    userUuid: string,
    tenderCriteria: ITenderCriteria,
    errors: Array<ErrorShape>,
) {
    try {
        const completionResponse = await getIsScoringCompleteForScorer(projectUuid, userUuid);
        if (completionResponse.status === 200) {
            tenderCriteria.isScoringCompleteForUser = (
                completionResponse.data as IsEntityScoringCompleteResponseDto
            ).isScoringComplete;
        } else {
            errors.push({ translation: "tenderCriteria.score.api.getCompleteStatusError" });
        }

        const projectScoringCompletionResponse = await getIsScoringCompleteForProject(projectUuid);
        if (projectScoringCompletionResponse.status === 200) {
            const scoringResponse = projectScoringCompletionResponse.data as IsEntityScoringCompleteResponseDto;
            tenderCriteria.isScoringCompleteForProject = scoringResponse.isScoringComplete;
            tenderCriteria.totalContributorScorers = scoringResponse.totalContributorScorers;
            tenderCriteria.completedContributorScorers = scoringResponse.completedContributorScorers;
        } else {
            errors.push({ translation: "tenderCriteria.score.api.getCompleteStatusError" });
        }
    } catch (error) {
        errors.push({ translation: "tenderCriteria.score.api.getCompleteStatusError" });
    }
}

async function fetchSectionsData(projectUuid: string, tenderCriteria: ITenderCriteria, errors: Array<ErrorShape>) {
    try {
        const sectionsResponse = await getProjectSections(projectUuid);
        if (sectionsResponse.status === 200) {
            const sections = [...(sectionsResponse.data as GetProjectSectionsResponseDto).sections];
            tenderCriteria.sections = sections.some(
                (section) => section.orderIndex === null || section.orderIndex === undefined,
            )
                ? sections.toSorted(sortByWeightingThenAlphabetically)
                : sections;
        } else {
            errors.push({ translation: "tenderCriteria.score.api.listSectionsError" });
        }
    } catch (error) {
        errors.push({ translation: "tenderCriteria.score.api.listSectionsError" });
    }
}

async function fetchAndSortQuestions(projectUuid: string, tenderCriteria: ITenderCriteria, errors: Array<ErrorShape>) {
    try {
        const questionsResponse = await listQuestions(projectUuid);
        if (questionsResponse.status === 200) {
            const questions: QuestionResponseDto[] = [];
            let questionsOrderIndexIsNull = false;

            tenderCriteria.sections.forEach((section) => {
                const sectionQuestions = (questionsResponse.data as ListQuestionsResponseDto).items.filter(
                    (question) => question.entityUuid.toLowerCase() === section.id.toLowerCase(),
                );

                if (
                    sectionQuestions.some(
                        (question) => question.orderIndex === null || question.orderIndex === undefined,
                    )
                ) {
                    questionsOrderIndexIsNull = true;
                }

                sectionQuestions.forEach((question) => {
                    question.answers.sort((a, b) => a.respondingCompanyName.localeCompare(b.respondingCompanyName));
                });

                questions.push(...sectionQuestions);
            });

            if (questionsOrderIndexIsNull) {
                questions.sort(sortByConditionalThenAlphabetically);
            }

            tenderCriteria.questions = questions;
        } else {
            errors.push({ translation: "tenderCriteria.score.api.listQuestionsError" });
        }
    } catch (error) {
        errors.push({ translation: "tenderCriteria.score.api.listQuestionsError" });
    }
}

function formatErrorMessages(errors: Array<ErrorShape>): string {
    return errors.map((error) => t(error.translation)).join(" | ");
}

export const fetchTenderCriteria = createAsyncThunk<
    ITenderCriteria,
    IFetchTenderCriteriaRequest,
    {
        state: AppStore;
        rejectValue: IRejectTenderValue;
    }
>("project/fetchTenderCriteria", async (request, { rejectWithValue, getState }) => {
    const errors: Array<ErrorShape> = [];
    const state = getState();
    let projectUuidToFetch = request?.projectUuid;
    const tenderCriteria = { ...defaultTenderCriteria };

    if (!request.projectUuid) {
        projectUuidToFetch = state.projectState.projectInformation?.uuid;
    }

    if (!projectUuidToFetch) {
        return rejectWithValue({
            fetchingErrorMessage: t("projectBrief.api.errorFetchingProject"),
            tenderCriteria,
        });
    }

    await fetchScoringData(projectUuidToFetch, request.userUuid, tenderCriteria, errors);

    await fetchSectionsData(projectUuidToFetch, tenderCriteria, errors);

    await fetchAndSortQuestions(projectUuidToFetch, tenderCriteria, errors);

    if (errors.length > 0) {
        return rejectWithValue({
            fetchingErrorMessage: formatErrorMessages(errors),
            tenderCriteria,
        });
    }

    return tenderCriteria;
});

export const fetchProject = createAsyncThunk<
    IProjectInformation,
    string | undefined,
    {
        state: AppStore;
        rejectValue: IRejectedProjectValue;
    }
>("project/fetchProject", async (projectUuid, { rejectWithValue, getState }) => {
    const errors: Array<ErrorShape> = [];
    const state = getState();
    let projectUuidToFetch = projectUuid;
    let projectInformation = defaultProjectInformation;

    if (!projectUuid) {
        projectUuidToFetch = state.projectState.projectInformation?.uuid;
    }
    if (!projectUuidToFetch) {
        return rejectWithValue({
            fetchingErrorMessage: t("projectBrief.api.errorFetchingProject"),
            projectInformation,
        });
    }

    const projectResponse = await getProject(projectUuidToFetch);
    if (projectResponse.data instanceof TransactionErrorDto || projectResponse.status !== 200) {
        return rejectWithValue({
            fetchingErrorMessage: t("projectBrief.api.errorFetchingProject"),
            projectInformation,
        });
    }
    projectInformation = {
        uuid: projectResponse.data._embedded.projectUuid,
        projectName: projectResponse.data.projectName,
        companyName: projectResponse.data.companyName,
        companyUuid: projectResponse.data._embedded.buyerCompanyUuid,
        manager: projectResponse.data._embedded.nameOfManager,
        managerUuid: projectResponse.data.managerUserUuid,
        description: projectResponse.data.description,
        timescale: projectResponse.data.timescale,
        budgetCurrency: projectResponse.data.budget.currencyOnly?.ccy
            ? (projectResponse.data.budget.currencyOnly.ccy as CurrencyType)
            : CurrencyType.GBP,
        buyerBudget: projectResponse.data.budget.buyerBudget,
        showBudgetToSupplier: projectResponse.data.budget.showBudgetToSupplier,
        budgetType: projectResponse.data.budget.budgetType,
        categories: [],
        status: projectResponse.data.status,
        tenderResponseDeadline: projectResponse.data.tenderResponseDeadline,
        clarificationQuestionDeadline: projectResponse.data.clarificationQuestionDeadline,
        targetProjectStartDate: projectResponse.data.targetProjectStartDate,
        targetProjectCompleteDate: projectResponse.data.targetProjectCompleteDate,
        expressionOfInterestDeadline: projectResponse.data.expressionOfInterestDeadline,
        decisionSummary: projectResponse.data.decisionSummary,
        sponsorUserUuid: projectResponse.data.sponsorUserUuid,
        sponsorName: projectResponse.data._embedded.nameOfSponsor,
        clientCompanyUuid: projectResponse.data._embedded.buyerCompanyUuid,
        clientName: projectResponse.data._embedded.buyerCompanyName,
        type: projectResponse.data.projectType,
        contributors: [],
    };

    const categoriesResponse = await getTaxonomyItemsForProject(projectUuidToFetch, true);
    if (categoriesResponse.data instanceof TransactionErrorDto || categoriesResponse.status !== 200) {
        errors.push({
            translation: "projectBrief.api.failGetProjectTaxonomy",
        });
    } else {
        projectInformation = {
            ...projectInformation,
            categories: categoriesResponse.data._embedded.items.map((item) => ({
                value: item._embedded.uuid,
                label: item._embedded.hierarchyDisplayName,
                status: item.selectionStatusId as CategorySelectionStatus,
            })),
        };
    }

    const projectUsersResponse = await getProjectUsers(projectUuidToFetch);
    if (projectUsersResponse.data instanceof TransactionErrorDto || projectUsersResponse.status !== 200) {
        errors.push({
            translation: "projectBrief.api.errorFetchingProjectUsers",
        });
    } else {
        projectInformation = {
            ...projectInformation,
            contributors: projectUsersResponse.data.projectUsers.filter(
                (user) => user.role.name === RolesEnum.ProjectContributor,
            ),
        };
    }

    if (errors.length > 0) {
        let fetchingErrorMessage = "";
        errors.forEach((error, index) => {
            fetchingErrorMessage = fetchingErrorMessage.concat(t(error.translation));
            if (index < errors.length - 1) {
                fetchingErrorMessage = fetchingErrorMessage.concat(" | ");
            }
        });
        return rejectWithValue({
            fetchingErrorMessage,
            projectInformation,
        });
    }

    return projectInformation;
});

const initialState: IProjectState = {
    fetchingErrorMessage: "",
    isFetchingProject: true,
    projectInformation: defaultProjectInformation,
    isFetchingTender: true,
    tenderCriteria: defaultTenderCriteria,
};

const updateScores = (scores: ScoreResponseDto[] | undefined, score: ScoreResponseDto): ScoreResponseDto[] => {
    if (!scores) {
        return [score];
    }

    if (scores.findIndex((s) => s.scoreUuid === score.scoreUuid) === -1) {
        return [...scores, score];
    }

    return scores.map((s) => {
        if (s.scoreUuid === score.scoreUuid) {
            return score;
        }
        return s;
    });
};

interface ISetQuestionScoreAction {
    score: ScoreResponseDto;
    questionUuid: string;
}

interface ISetScoreCompleteAction {
    isProjectManager: boolean;
}

export const projectSlice = createSlice({
    name: "project",
    initialState,
    reducers: {
        clearProjectInformation: (state) => {
            state.projectInformation = defaultProjectInformation;
        },
        setProjectInformation: (state, action: PayloadAction<IProjectInformation>) => {
            state.projectInformation = {
                ...action.payload,
            };
        },
        updateProjectInformation: (state, action: PayloadAction<IProjectInformation>) => {
            state.projectInformation = {
                ...state.projectInformation,
                ...action.payload,
            };
        },
        setFetching: (state, action: PayloadAction<boolean>) => {
            state.isFetchingProject = action.payload;
            state.isFetchingTender = action.payload;
        },
        setQuestionScore: (state, action: PayloadAction<ISetQuestionScoreAction>) => {
            state.tenderCriteria.questions = state.tenderCriteria.questions.map((q) => {
                if (q.questionUuid === action.payload.questionUuid) {
                    return {
                        ...q,
                        answers: q.answers.map((a) => {
                            if (a.answerUuid === action.payload.score.answerUuid) {
                                return { ...a, scores: updateScores(a.scores, action.payload.score) };
                            }
                            return a;
                        }),
                    };
                }
                return q;
            });
        },
        setTenderScoringComplete: (state, action: PayloadAction<ISetScoreCompleteAction>) => {
            state.tenderCriteria.isScoringCompleteForProject = action.payload.isProjectManager;
            state.tenderCriteria.isScoringCompleteForUser = true;
            state.projectInformation.status = action.payload.isProjectManager
                ? ProjectStatus.Results
                : state.projectInformation.status;
        },
        setTenderResponseDeadline: (state, action: PayloadAction<ProjectResponseDto>) => {
            state.projectInformation.tenderResponseDeadline = action.payload.tenderResponseDeadline;
            state.projectInformation.status = action.payload.status;
        },
        setExpressionOfInterestDeadline: (state, action: PayloadAction<ProjectResponseDto>) => {
            state.projectInformation.expressionOfInterestDeadline = action.payload.expressionOfInterestDeadline;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProject.pending, (state) => {
            state.isFetchingProject = true;
            state.fetchingErrorMessage = "";
        });
        builder.addCase(fetchProject.rejected, (state, action) => {
            state.isFetchingProject = false;
            if (action?.payload?.fetchingErrorMessage) {
                state.fetchingErrorMessage = action?.payload?.fetchingErrorMessage;
            }
            if (action?.payload?.projectInformation) {
                state.projectInformation = action?.payload?.projectInformation;
            }
        });
        builder.addCase(fetchProject.fulfilled, (state, action) => {
            state.isFetchingProject = false;
            state.projectInformation = action.payload;
        });
        builder.addCase(fetchTenderCriteria.pending, (state) => {
            state.isFetchingTender = true;
            state.fetchingErrorMessage = "";
        });
        builder.addCase(fetchTenderCriteria.rejected, (state, action) => {
            state.isFetchingTender = false;
            if (action?.payload?.fetchingErrorMessage) {
                state.fetchingErrorMessage = action?.payload?.fetchingErrorMessage;
            }
            if (action?.payload?.tenderCriteria) {
                state.tenderCriteria = action?.payload?.tenderCriteria;
            }
        });
        builder.addCase(fetchTenderCriteria.fulfilled, (state, action) => {
            state.isFetchingTender = false;
            state.tenderCriteria = action.payload;
        });
    },
});

const { actions, reducer } = projectSlice;

export const {
    clearProjectInformation,
    setProjectInformation,
    setQuestionScore,
    setTenderScoringComplete,
    setTenderResponseDeadline,
    setExpressionOfInterestDeadline,
    updateProjectInformation,
    setFetching,
} = actions;

export default reducer;
