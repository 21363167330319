import { faFolderArrowDown } from "@fortawesome/pro-regular-svg-icons";
import { Alert, Button, Copy, Popup } from "@maistro/components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import ScoreTenderSummarySectionRow from "features/project/tenderCriteria/scoringSummary/ScoreTenderSummarySectionRow";
import useCurrentUser from "hooks/useCurrentUser";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { ITheme } from "styles/themes/types";
import { AnswerDto } from "types/dtos/questions/AnswerResponseDto";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import { SectionDto } from "types/dtos/questions/sections/SectionDto";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

const useStyles = createUseStyles((theme: ITheme) => ({
    buttonControl: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing.medium,
    },
    copy: {
        marginBottom: theme.spacing.medium,
    },
    completion: {
        marginTop: theme.spacing.medium,
        marginBottom: theme.spacing.medium,
        display: "flex",
        flexDirection: "column",
    },
    scoreSummary: {
        fontSize: "14px",
        fontWeight: "bold",
        color: theme.colors.primary,
        paddingRight: theme.spacing.small,
    },
    sectionsComplete: {
        color: theme.colors.primary,
        paddingRight: theme.spacing.medium,
    },
    answersComplete: {
        color: theme.colors.primary,
        paddingRight: theme.spacing.medium,
    },
    contributorsComplete: {
        color: theme.colors.primary,
    },
    subBanner: {
        display: "flex",
        gap: theme.spacing.medium,
        alignItems: "center",
        justifyContent: "space-between",
    },
    subBannerButtons: {
        gap: theme.spacing.small,
    },
    [`@media (min-width: ${theme.breakpoints.md}px)`]: {
        subBanner: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "baseline",
            marginBottom: theme.spacing.small,
        },
        subBannerButtons: {
            gap: theme.spacing.small,
            display: "flex",
            justifyContent: "right",
        },
        completion: {
            marginBottom: theme.spacing.xLarge,
            display: "flex",
            flexDirection: "row",
        },
    },
}));

interface IScoreTenderSummaryDisplayProps {
    sections: SectionDto[];
    questions: QuestionResponseDto[];
    completedContributorScorers: number;
    totalContributorScorers: number;
    projectUuid: string;
    completeScoring: () => Promise<void>;
    isProjectManager: boolean;
    isScoringComplete: boolean;
    displayScoringClosedMessage: boolean;
    projectHasFiles: boolean;
    buyerCompanyUuid: string;
}

const ScoreTenderSummaryDisplay: React.FC<IScoreTenderSummaryDisplayProps> = (props) => {
    const classes = useStyles();

    const {
        projectUuid,
        sections,
        questions,
        completedContributorScorers,
        totalContributorScorers,
        completeScoring,
        isProjectManager,
        isScoringComplete,
        displayScoringClosedMessage,
        projectHasFiles,
        buyerCompanyUuid,
    } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { userHasProjectPermission, myUuid } = useCurrentUser();
    const [scoringCompletePopupVisible, setScoringCompletePopupVisible] = useState(false);
    const [popupMessage, setPopupMessage] = useState("popups.tenderCriteria.scoringComplete.message");
    const [popupTitleMessage, setPopupTitleMessage] = useState("popups.tenderCriteria.scoringComplete.title");

    useEffect(() => {
        if (isProjectManager) {
            if (completedContributorScorers < totalContributorScorers) {
                setPopupTitleMessage("popups.tenderCriteria.scoringComplete.pmTitleWarning");
                setPopupMessage("popups.tenderCriteria.scoringComplete.pmWarningContributorsCompletion");
            } else {
                setPopupMessage("popups.tenderCriteria.scoringComplete.pmMessage");
            }
        }
    }, [completedContributorScorers, totalContributorScorers, isProjectManager]);

    const haveScores = (answer: AnswerDto) => {
        return (
            !!answer.scores &&
            answer.scores.length > 0 &&
            answer.scores.some((score) => score.updatedByUserUuid === myUuid)
        );
    };

    const questionInSection = (section: SectionDto, question: QuestionResponseDto) =>
        question.entityUuid.toLowerCase() === section.id.toLowerCase();

    const isSectionComplete = (section: SectionDto) => {
        return questions
            .filter((question) => questionInSection(section, question))
            .every((question) => question.answers?.some(haveScores));
    };

    const answers = questions.map((question) => question.answers).flat();
    const scoredAnswersCount = answers.filter(haveScores).length;
    const scoredSectionsCount = sections.filter(isSectionComplete).length;

    const allAnswersScored = answers.every(haveScores);
    const canCompleteScoring = !isScoringComplete && allAnswersScored;
    const canScoreAnswers =
        !isScoringComplete && userHasProjectPermission(PermissionsEnum.CreateScore, projectUuid, buyerCompanyUuid);

    const canModerateScores =
        isScoringComplete && userHasProjectPermission(PermissionsEnum.OverrideScore, projectUuid, buyerCompanyUuid);

    const onViewResults = () => {
        if (isProjectManager && isScoringComplete) {
            navigate(buildPath(routes.projects.awardSupplier, { projectUuid }));
        } else {
            navigate(buildPath(routes.projects.monitor, { projectUuid }));
        }
    };

    return (
        <>
            {displayScoringClosedMessage ? (
                <Alert type="error" label={t("tenderSummaryScreen.scoringClosedMessage")} testid="scoring-closed" />
            ) : (
                <Copy className={classes.copy}>{t("tenderSummaryScreen.subtitle")}</Copy>
            )}
            <div className={classes.subBanner}>
                <div className={classes.completion}>
                    <span>
                        <span className={classes.scoreSummary}>{t("tenderSummaryScreen.sectionsComplete")}</span>
                        <span className={classes.sectionsComplete}>
                            {scoredSectionsCount}/{sections.length}
                        </span>
                    </span>
                    <span>
                        <span className={classes.scoreSummary}>{t("tenderSummaryScreen.answersScored")}</span>
                        <span className={classes.answersComplete}>
                            {scoredAnswersCount}/{answers.length}
                        </span>
                    </span>
                    {isProjectManager && (
                        <span>
                            <span className={classes.scoreSummary}>{t("tenderSummaryScreen.contributorsScored")}</span>
                            <span className={classes.contributorsComplete}>
                                {completedContributorScorers}/{totalContributorScorers}
                            </span>
                        </span>
                    )}
                </div>
                <div className={classes.subBannerButtons}>
                    {projectHasFiles && (
                        <Button
                            className="hidden-tablet"
                            label={t("fileUpload.archive.documents")}
                            icon={faFolderArrowDown}
                            variant="outline"
                            onClick={() => {
                                navigate(buildPath(routes.projects.tenderDocuments, { projectUuid }));
                            }}
                            testid="documents"
                        />
                    )}
                    {isScoringComplete && (
                        <Button
                            className={classes.viewResults}
                            type="submit"
                            label={t("tenderSummaryScreen.viewResults")}
                            onClick={onViewResults}
                            chevron
                            testid="view-results"
                        />
                    )}
                    {canCompleteScoring && (
                        <>
                            <Alert type="success" label={t("tenderSummaryScreen.scoringComplete")} />
                            <Button
                                type="submit"
                                label={t("tenderSummaryScreen.submitScores")}
                                onClick={() => setScoringCompletePopupVisible(true)}
                                testid="complete-scoring"
                            />
                        </>
                    )}
                </div>
            </div>

            {sections.map((section, index) => (
                <ScoreTenderSummarySectionRow
                    key={section.id}
                    projectUuid={projectUuid}
                    section={section}
                    questions={questions.filter((question) => questionInSection(section, question))}
                    haveScores={haveScores}
                    canScoreAnswers={canScoreAnswers}
                    canModerateScores={canModerateScores}
                    expandedByDefault={index === 0}
                    testid={`section-${section.id}`}
                />
            ))}
            <Popup
                title={t(popupTitleMessage)}
                message={t(popupMessage)}
                isOpen={scoringCompletePopupVisible}
                primaryActionText={t("popups.tenderCriteria.scoringComplete.cta.primary")}
                onPrimaryAction={completeScoring}
                secondaryActionText={t("popups.tenderCriteria.scoringComplete.cta.secondary")}
                onSecondaryAction={() => setScoringCompletePopupVisible(false)}
                onClose={() => setScoringCompletePopupVisible(false)}
                testid="scoring-complete-popup"
            />
        </>
    );
};
export default ScoreTenderSummaryDisplay;
