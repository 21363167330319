import { Alert, Button, Copy, Drawer, Popup } from "@maistro/components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import useFileUpload from "features/files/useFileUpload";
import SupplierQuestionRow from "features/project/components/tender-questions/SupplierQuestionRow";
import { isSectionWithMandatoryQuestions, listTenderCriteriaQuestions } from "features/project/helpers/questionHelpers";
import { getSectionTitle } from "features/project/helpers/sectionHelpers";
import useProjectFiles from "features/project/hooks/useProjectFiles";
import NotInterestedWithdrawButtons from "features/project/shared/projectResponse/NotInterestedWithdrawButtons";
import useQuestionStyles from "features/project/shared/tender-questions/useQuestionStyles";
import AnswerQuestionForm, { IQuestionInformation } from "features/project/supplierTenderResponse/AnswerQuestionForm";
import SectionRow from "features/project/supplierTenderResponse/components/SectionRow/SectionRow";
import { IProjectResponseInformation } from "features/project/types";
import dateTimeService from "services/dateTimeService";
import { ITheme } from "styles/themes/types";
import { AnswerDto } from "types/dtos/questions/AnswerResponseDto";
import { CreateOrUpdateTenderCriteriaAnswerForSubmissionRequestDto } from "types/dtos/questions/CreateOrUpdateTenderCriteriaAnswerForSubmissionRequestDto";
import { ISectionQuestions } from "types/dtos/questions/ISectionQuestions";
import { QuestionResponseDto } from "types/dtos/questions/QuestionResponseDto";
import { SectionDto } from "types/dtos/questions/sections/SectionDto";
import EntityType from "types/enums/questions/EntityType";
import SupplierSubmissionStatus from "types/enums/supplierSubmissions/SupplierSubmissionStatus";

const useStyles = createUseStyles((theme: ITheme) => ({
    buttonContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing.medium,
        marginTop: theme.spacing.medium,
    },
    statusContainer: {
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "column",
        gap: theme.spacing.xSmall,
        marginBottom: theme.spacing.small,
    },
    flexContainer: {
        display: "flex",
        gap: theme.spacing.small,
    },
    label: {
        fontWeight: 500,
    },
    buttonWidth: {
        minWidth: 161,
    },
    answerQuestionDrawer: {},

    [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
        answerQuestionDrawer: {
            width: 460,
        },
    },

    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        statusContainer: {
            flexDirection: "row",
            gap: theme.spacing.medium,
        },
    },
}));

interface ISupplierTenderCriteriaDisplayProps {
    sections: SectionDto[];
    sectionQuestions: ISectionQuestions[];
    answerQuestion: (
        questionId: string,
        answer: CreateOrUpdateTenderCriteriaAnswerForSubmissionRequestDto,
        fileUuids: Array<string>,
    ) => Promise<string | null>;
    submitTender: () => void;
    cancelTender: () => void;
    withdrawTender: () => void;
    canUpdate: boolean;
    isTenderSubmitting: boolean;
    calculateNextQuestionUuid: (currentQuestionUuid: string) => string | null;
    projectUuid?: string;
    buyerCompanyUuid: string;
    projectResponseInformation: IProjectResponseInformation;
    isUpdatingProjectStatus: boolean;
}

const statusesAllowingEditing = [
    SupplierSubmissionStatus.Submitted,
    SupplierSubmissionStatus.Interested,
    SupplierSubmissionStatus.Invited,
    SupplierSubmissionStatus.NotInterested,
    SupplierSubmissionStatus.Responding,
];

const SupplierTenderQuestionsDisplay: React.FC<ISupplierTenderCriteriaDisplayProps> = (props) => {
    const classes = useStyles();
    const questionClasses = useQuestionStyles();

    const {
        sections,
        submitTender,
        sectionQuestions,
        cancelTender,
        withdrawTender,
        canUpdate,
        isTenderSubmitting,
        answerQuestion,
        calculateNextQuestionUuid,
        projectUuid,
        buyerCompanyUuid,
        projectResponseInformation,
        isUpdatingProjectStatus,
    } = props;

    const { t } = useTranslation();

    const [isAnswerDrawerOpen, setIsAnswerDrawerOpen] = useState(false);
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [totalSections, setTotalSections] = useState(0);
    const [completedQuestionCount, setCompletedQuestionCount] = useState(0);
    const [completedSectionCount, setCompletedSectionCount] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState<AnswerDto | null>(null);
    const [selectedQuestionUuid, setSelectedQuestionUuid] = useState<string | undefined>(undefined);
    const [nextQuestionUuid, setNextQuestionUuid] = useState<string | null>(null);
    const [selectedQuestionInformation, setSelectedQuestionInformation] = useState<IQuestionInformation | null>(null);
    const [editInProgress, setEditInProgress] = useState(false);

    const [isConfirmClosePopupOpen, setIsConfirmClosePopupOpen] = useState(false);
    const [isConfirmSubmissionPopupOpen, setIsConfirmSubmissionPopupOpen] = useState(false);
    const [hasConfirmSubmissionPopupOpened, setHasConfirmSubmissionPopupOpened] = useState(false);

    const { projectFiles: questionFiles } = useProjectFiles(projectUuid, EntityType.Question, selectedQuestionUuid);
    const {
        projectFiles: answerFiles,
        setProjectFiles: setAnswerFiles,
        removeFile: removeAnswerFile,
    } = useFileUpload(projectUuid, EntityType.Answer, selectedAnswer?.answerUuid);

    const isSectionLoading = (section: string) => {
        const questions = sectionQuestions.find((el) => el.id === section);
        if (!questions) {
            return true;
        }
        return questions.isLoading;
    };

    const isSectionComplete = (sectionId: string) => {
        const questions = sectionQuestions.find((el) => el.id === sectionId)?.items;
        return questions?.every((q) => q.answersCount > 0);
    };

    const doAllSectionsHaveAnswers = () => {
        const allComplete = sectionQuestions.every((section) => isSectionComplete(section.id));

        return allComplete;
    };

    const populateQuestionInformation = (
        question: QuestionResponseDto,
        sectionId: string,
    ): IQuestionInformation | null => {
        const section = sections.find((s) => s.id === sectionId);
        const questions = sectionQuestions.find((sq) => sq.id === sectionId)?.items;

        if (!questions || !section) {
            return null;
        }

        return {
            sectionName: getSectionTitle(section),
            sectionWeighting: section.weighting ?? 0,
            questionCount: questions.length,
            questionIndex: questions.findIndex((q) => q.questionUuid === question.questionUuid) + 1,
            answerTypeId: question.answerTypeId.toString(),
            questionUuid: question.questionUuid,
            questionText: question.questionText,
            isConditional: question.isConditional,
            allowAttachments: !question.disableFileAttachments,
        };
    };

    const openAnswerDrawer = (question: QuestionResponseDto, sectionId: string) => {
        const existingAnswer = question.answersCount > 0 ? question.answers[0] : null;
        setSelectedAnswer(existingAnswer);
        setSelectedQuestionUuid(question.questionUuid);
        setNextQuestionUuid(calculateNextQuestionUuid(question.questionUuid));
        setSelectedQuestionInformation(populateQuestionInformation(question, sectionId));
        setIsAnswerDrawerOpen(true);
    };

    const submitAnswer = async (
        answer: CreateOrUpdateTenderCriteriaAnswerForSubmissionRequestDto,
        moveNext: boolean,
    ): Promise<boolean> => {
        if (!selectedQuestionInformation) {
            return false;
        }
        const newFiles = answerFiles.filter((file) => file.newUpload);
        let fileUuids: Array<string> = [];
        if (newFiles.length && projectUuid) {
            fileUuids = newFiles.map((file) => file.fileId);
        }

        const answerUuid = await answerQuestion(selectedQuestionInformation.questionUuid, answer, fileUuids);

        if (answerUuid) {
            setSelectedQuestionUuid(undefined);

            if (moveNext && nextQuestionUuid !== null) {
                const nextSectionQuestion = sectionQuestions.find((sq) =>
                    sq.items.some((q) => q.questionUuid === nextQuestionUuid),
                );
                const nextQuestion = nextSectionQuestion?.items.find((q) => q.questionUuid === nextQuestionUuid);
                if (!nextQuestion || !nextSectionQuestion) {
                    setIsAnswerDrawerOpen(false);
                } else {
                    openAnswerDrawer(nextQuestion, nextSectionQuestion.id);
                }
            } else {
                setIsAnswerDrawerOpen(false);
            }
            setEditInProgress(false);
            setSelectedAnswer(null);
            setAnswerFiles([]);
        }

        return answerUuid !== null;
    };

    const confirmDrawerClose = useCallback(() => {
        if (editInProgress) {
            setIsConfirmClosePopupOpen(true);
        } else {
            setIsAnswerDrawerOpen(false);
            setSelectedAnswer(null);
        }
    }, [editInProgress]);

    const deadlineHasPassed =
        dateTimeService.dateIsInPast(projectResponseInformation.tenderResponseDeadline) ||
        statusesAllowingEditing.find((s) => s === projectResponseInformation?.status) == null;

    useEffect(() => {
        setTotalSections(sections.length);
        const totalQuestionCount = sectionQuestions.reduce((total, section) => total + section.items.length, 0);
        setTotalQuestions(totalQuestionCount);

        setCompletedSectionCount(
            sectionQuestions.reduce(
                (total, section) => total + (section.items.every((q) => q.answersCount > 0) ? 1 : 0),
                0,
            ),
        );

        const completedQuestionCountForSection = (section: ISectionQuestions) => {
            return section.items.reduce((total, question) => total + question.answersCount, 0);
        };

        const allSectionQuestionsCompletedCount = sectionQuestions.reduce(
            (total, section) => total + completedQuestionCountForSection(section),
            0,
        );
        setCompletedQuestionCount(allSectionQuestionsCompletedCount);

        if (
            !hasConfirmSubmissionPopupOpened &&
            totalQuestionCount > 0 &&
            allSectionQuestionsCompletedCount === totalQuestionCount &&
            !deadlineHasPassed &&
            projectResponseInformation.status !== SupplierSubmissionStatus.Submitted
        ) {
            setIsConfirmSubmissionPopupOpen(true);
            setHasConfirmSubmissionPopupOpened(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectionQuestions, sections.length]);

    const renderQuestionsForSection = (sectionId: string) => {
        const questions = listTenderCriteriaQuestions(sectionId, sectionQuestions);
        return questions.map((q) => (
            <div className={questionClasses.questionContainer} key={q.questionUuid}>
                <SupplierQuestionRow
                    testid={`question-${q.questionUuid}`}
                    className={questionClasses.questionRow}
                    questionText={q.questionText}
                    isMandatoryQuestion={q.isConditional}
                    hasAttachments={q.hasAttachments}
                    isComplete={q.answersCount > 0}
                />
                <div>
                    {q.answersCount > 0 ? (
                        <Button
                            variant="outline"
                            label={`${t("supplierTenderQuestions.editAnswer")}`}
                            onClick={() => openAnswerDrawer(q, sectionId)}
                            testid={`edit-question-${q.questionUuid}`}
                            disabled={!canUpdate}
                            className={classes.buttonWidth}
                        />
                    ) : (
                        <Button
                            color="dark"
                            label={`${t("supplierTenderQuestions.answerQuestion")}`}
                            onClick={() => openAnswerDrawer(q, sectionId)}
                            testid={`answer-question-${q.questionUuid}`}
                            disabled={!canUpdate}
                            className={classes.buttonWidth}
                        />
                    )}
                </div>
            </div>
        ));
    };

    const closeConfirmClosePopup = useCallback(() => {
        setIsConfirmClosePopupOpen(false);
    }, []);

    const openConfirmSubmissionPopup = useCallback(() => {
        setIsConfirmSubmissionPopupOpen(true);
    }, []);

    const closeConfirmSubmissionPopup = useCallback(() => {
        setIsConfirmSubmissionPopupOpen(false);
    }, []);

    const onConfirmCloseAnswer = useCallback(() => {
        setEditInProgress(false);
        setIsConfirmClosePopupOpen(false);
        setIsAnswerDrawerOpen(false);
        setSelectedAnswer(null);
        setSelectedQuestionUuid(undefined);
    }, []);

    return (
        <React.Fragment>
            {!deadlineHasPassed ? (
                <Copy className={questionClasses.copy}>{t("supplierTenderQuestions.subTitle")}</Copy>
            ) : (
                <Alert type="error" label={t("projectResponse.deadlineExpired")} testid="deadline-expired" />
            )}
            <div className={classes.statusContainer}>
                <div className={classes.flexContainer}>
                    <Copy className={classes.label}>{t("supplierTenderQuestions.sectionsComplete")}</Copy>
                    <Copy>{`${completedSectionCount}\\${totalSections}`}</Copy>
                </div>
                <div className={classes.flexContainer}>
                    <Copy className={classes.label}>{t("supplierTenderQuestions.questionsAnswered")}</Copy>
                    <Copy>{`${completedQuestionCount}\\${totalQuestions}`}</Copy>
                </div>
            </div>
            {sections.map((section) => (
                <div key={`${section.id}`} className={questionClasses.flexContainer}>
                    <SectionRow
                        testid={`section-${section.id}`}
                        className={questionClasses.sectionRow}
                        title={getSectionTitle(section)}
                        totalQuestions={listTenderCriteriaQuestions(section.id, sectionQuestions).length}
                        weighting={section.weighting ?? 0}
                        hasMandatoryQuestions={isSectionWithMandatoryQuestions(section.id, sectionQuestions)}
                        isLoading={isSectionLoading(section.id)}
                        startExpanded={section.id === sections[0]?.id}
                        isComplete={isSectionComplete(section.id)}
                    >
                        {renderQuestionsForSection(section.id)}
                    </SectionRow>
                </div>
            ))}
            <div className={classes.buttonContainer}>
                <Button
                    label={`${t("supplierTenderQuestions.submitTender")}`}
                    disabled={!doAllSectionsHaveAnswers() || !canUpdate || deadlineHasPassed}
                    chevron
                    size="large"
                    onClick={openConfirmSubmissionPopup}
                    testid="submit-tender"
                    loading={isTenderSubmitting}
                />
                <NotInterestedWithdrawButtons
                    supplierSubmission={projectResponseInformation}
                    declareNotInterested={cancelTender}
                    withdraw={withdrawTender}
                />
            </div>
            <Popup
                title={t("tenderQuestions.confirmCloseTitle")}
                message={t("tenderQuestions.confirmCloseSubTitle")}
                isOpen={isConfirmClosePopupOpen}
                primaryActionText={t("tenderQuestions.confirmCloseConfirm")}
                secondaryActionText={t("common.cancel")}
                onPrimaryAction={onConfirmCloseAnswer}
                onSecondaryAction={closeConfirmClosePopup}
                onClose={closeConfirmClosePopup}
                testid="confirm-answer-drawer-close-popup"
            />
            <Popup
                title={t("popups.supplierTenderQuestions.confirmSubmission.title")}
                message={t("popups.supplierTenderQuestions.confirmSubmission.message")}
                isOpen={isConfirmSubmissionPopupOpen}
                primaryActionText={t("popups.supplierTenderQuestions.confirmSubmission.cta.primary")}
                secondaryActionText={t("popups.supplierTenderQuestions.confirmSubmission.cta.secondary")}
                onPrimaryAction={submitTender}
                onSecondaryAction={closeConfirmSubmissionPopup}
                onClose={closeConfirmSubmissionPopup}
                testid="confirm-submission-popup"
                disabled={isUpdatingProjectStatus}
            />
            <Drawer
                className={classes.answerQuestionDrawer}
                isOpen={isAnswerDrawerOpen}
                onClose={confirmDrawerClose}
                testid="answer-question-drawer"
            >
                <AnswerQuestionForm
                    question={selectedQuestionInformation}
                    selectedAnswer={selectedAnswer}
                    nextQuestionId={nextQuestionUuid}
                    onSubmit={submitAnswer}
                    setEditInProgress={setEditInProgress}
                    projectUuid={projectUuid}
                    buyerCompanyUuid={buyerCompanyUuid}
                    questionFiles={questionFiles}
                    projectResponseInformation={projectResponseInformation}
                    files={answerFiles}
                    removeFile={removeAnswerFile}
                    setFiles={setAnswerFiles}
                />
            </Drawer>
        </React.Fragment>
    );
};

export default SupplierTenderQuestionsDisplay;
