import { faBuilding, faChartBar, faGear, faHome } from "@fortawesome/pro-regular-svg-icons";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { matchPath, useLocation } from "react-router-dom";

import { INavItem } from "components/Layout/Nav";
import useCompany from "hooks/useCompany";
import useCurrentUser from "hooks/useCurrentUser";
import useReduxSelector from "hooks/useReduxSelector";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { setNavigationItems } from "store/navigationSlice";
import FeatureToggle from "types/enums/companies/FeatureToggle";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

const useNavigation = () => {
    const { accessToken } = useReduxSelector((state) => state.authenticationState);
    const { navigationItems: navItemsInState } = useReduxSelector((state) => state.navigationState);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const { myCompanyUuid, userHasPermission, userHasCompanyPermission } = useCurrentUser();
    const { companyHasFeature } = useCompany();

    const visibleNavItems = useMemo(() => {
        return navItemsInState || ([] as INavItem[]);
    }, [navItemsInState]);

    const locationMatchesPathIn = useCallback(
        (navItemPaths: string[]) => {
            return navItemPaths.some((pathsToMatch) => matchPath(pathname, pathsToMatch));
        },
        [pathname],
    );

    const userHasSupplierList = useCallback(() => {
        return (
            !!myCompanyUuid && userHasCompanyPermission(PermissionsEnum.ReadSupplierRelationshipStatus, myCompanyUuid)
        );
    }, [myCompanyUuid, userHasCompanyPermission]);

    const userIsAuthenticated = useCallback(() => {
        return JSON.stringify(accessToken) !== "{}";
    }, [accessToken]);

    const userCanViewReports = useCallback(() => {
        return userHasPermission(PermissionsEnum.ReadReport, myCompanyUuid);
    }, [myCompanyUuid, userHasPermission]);

    const userCanManageSupport = useCallback(() => {
        return userHasPermission(PermissionsEnum.ManageSupport);
    }, [userHasPermission]);

    const userCanViewCompanyProfile = useCallback(() => {
        return myCompanyUuid !== undefined && userHasPermission(PermissionsEnum.UpdateCompany, myCompanyUuid);
    }, [myCompanyUuid, userHasPermission]);

    const userCanViewApprovals = useCallback(() => {
        return companyHasFeature(FeatureToggle.Approvals) && userHasPermission(PermissionsEnum.CanBuy);
    }, [companyHasFeature, userHasPermission]);

    const userHasSettingsAccess = useCallback(() => {
        return userCanViewCompanyProfile() || userCanViewApprovals() || userCanManageSupport();
    }, [userCanManageSupport, userCanViewApprovals, userCanViewCompanyProfile]);

    const navItems: INavItem[] = useMemo(
        () => [
            {
                label: t("components.dashboard"),
                icon: faHome,
                route: routes.common.dashboard.path,
                isActive: locationMatchesPathIn([]),
                isVisible: userIsAuthenticated,
            },
            {
                label: t("components.reports"),
                icon: faChartBar,
                route: routes.common.reports.path,
                isActive: locationMatchesPathIn(["/reports"]),
                isVisible: userCanViewReports,
            },
            {
                label: t("components.supplierList"),
                icon: faBuilding,
                route: buildPath(routes.company.companySuppliers, { companyUuid: myCompanyUuid ?? "" }),
                isActive: locationMatchesPathIn(["/company/:companyUuid/suppliers"]),
                isVisible: userHasSupplierList,
            },
            {
                label: t("components.settings"),
                icon: faGear,
                route: routes.common.settings.path,
                isActive: locationMatchesPathIn(["/settings"]),
                isVisible: userHasSettingsAccess,
            },
        ],
        [
            locationMatchesPathIn,
            myCompanyUuid,
            t,
            userHasSettingsAccess,
            userCanViewReports,
            userHasSupplierList,
            userIsAuthenticated,
        ],
    );

    useEffect(() => {
        const filteredNavItems = navItems.filter((item) => !item.isVisible || item.isVisible());

        dispatch(setNavigationItems(filteredNavItems));
    }, [dispatch, navItems]);

    return { navigationItems: visibleNavItems };
};

export default useNavigation;
